<template>
  <Tabs type="card" :value="tabName">
    <TabPane label="需求评估信息" name="name1">
      <!-- 主体内容 -->
      <div class="container">
        <!-- 左侧菜单 -->
        <Menu theme="light" :active-name="selectMenuName" @on-select="selectMenu" class="menu">
          <MenuGroup title="菜单栏">
            <MenuItem name="NeedBaseInfo">服务对象基本资料/分级分类/案主问题及需求</MenuItem>
            <MenuItem name="NeedWork">社工评估及处理跟进工作</MenuItem>
          </MenuGroup>
        </Menu>
        <div class="rightContent">
          <keep-alive>
            <component
              :is="componentName"
              :needData="needData"
              :demandData="demandData"
              :orgCodeList="orgCodeList"
              :nationList="nationList"
              :relationshipList="relationshipList"
            ></component>
          </keep-alive>
        </div>
      </div>
    </TabPane>
    <TabPane label="档案" name="name2">
      <SeeArchives :archivesData="archivesData" :orgCodeList="orgCodeList" :nationList="nationList"></SeeArchives>
    </TabPane>
    <TabPane label="开展服务情况" name="name3">
      <SeeDevService class="service" :info="info"></SeeDevService>
    </TabPane>
    <TabPane label="服务评估情况" name="name4">
      <SeeAssessment class="assessment" :info="info"></SeeAssessment>
    </TabPane>
    <TabPane label="转介信息" name="name5" class="referral" v-if="showReferral">
      <Referral :formDisabled="true" :infoId="info"></Referral>
    </TabPane>
    <TabPane label="定期回访情况" name="name6">
      <SeeReturnVisit class="returnVisit" :info="info"></SeeReturnVisit>
    </TabPane>
  </Tabs>
</template>

<script>
import NeedBaseInfo from "@/views/archives/childrens/seeDemandChildrens/needBaseInfo";
import NeedWork from "@/views/archives/childrens/seeDemandChildrens/needWork";
import SeeArchives from "@/views/archives/childrens/seeArchives";
import SeeDevService from "@/views/archives/childrens/seeDevService";
import SeeAssessment from "@/views/archives/childrens/seeAssessment";
import Referral from "@/views/archives/childrens/referral";
import SeeReturnVisit from "@/views/archives/childrens/seeReturnVisit";
export default {
  props: ["tabName", "info", "showReferral"],
  data() {
    return {
      selectMenuName: "NeedBaseInfo",
      componentName: "NeedBaseInfo",
      // 个体/家庭/社会数据
      needData: {
        个体层面: {},
        家庭层面: {},
        社会层面: {}
      },
      demandData: {}, //需求评估
      archivesData: {}, //档案
      orgCodeList: [],
      nationList: [],
      relationshipList: []
    };
  },
  methods: {
    //选择菜单
    selectMenu(name) {
      this.componentName = name;
    },
    //动态修改案主问题数据结构
    getQuestionList(arr) {
      arr.map(item => {
        if (item.topType == 1) {
          if (!this.needData["个体层面"].hasOwnProperty(item.questionType)) {
            //判断当前对象是否有这个属性，没有就添加
            this.needData["个体层面"][item.questionType] = [];
          }
          this.needData["个体层面"][item.questionType].push({
            questionId: item.questionId,
            questionDesc: item.questionDesc,
            questionResult: item.questionResult
          });
        } else if (item.topType == 2) {
          if (!this.needData["家庭层面"].hasOwnProperty(item.questionType)) {
            //判断当前对象是否有这个属性，没有就添加
            this.needData["家庭层面"][item.questionType] = [];
          }
          this.needData["家庭层面"][item.questionType].push({
            questionId: item.questionId,
            questionDesc: item.questionDesc,
            questionResult: item.questionResult
          });
        } else {
          if (!this.needData["社会层面"].hasOwnProperty(item.questionType)) {
            //判断当前对象是否有这个属性，没有就添加
            this.needData["社会层面"][item.questionType] = [];
          }
          this.needData["社会层面"][item.questionType].push({
            questionId: item.questionId,
            questionDesc: item.questionDesc,
            questionResult: item.questionResult
          });
        }
      });
    }
  },
  async created() {
    //获取需求评估信息
    await this.$get("/archives/api/pc/archiveUser/updateQuestionDetail", {
      archiveId: this.info
    }).then(res => {
      this.$Message.destroy();
      if (res.code == 200 && res.data) {
        if (res.data.archiveDetailVo && res.data.archiveDetailVo.orgCode) {
          res.data.archiveDetailVo.orgName = "";
        }
        this.demandData = res.data;
        this.getQuestionList(res.data.archiveQuestionConfigVos);
      } else {
        this.$Message.error({
          background: true,
          content: res.desc
        });
      }
    });
    //获取所属社区
    this.$get("/old/api/pc/project/selectStaffManagerCommunity", {
      staffId: parent.vue.loginInfo.userinfo.id,
      orgCode: parent.vue.loginInfo.userinfo.orgCode
    }).then(res => {
      if (res.code == 200 && res.dataList && res.dataList.length > 0) {
        this.orgCodeList = res.dataList.map(item => {
          return {
            label: item.fullName,
            value: item.code
          };
        });
      } else {
        this.$Message["error"]({
          background: true,
          content: "获取社区列表失败！请联系管理员"
        });
      }
    });
    //获取民族
    this.$get("/datamsg/api/common/sys/findDictByDictType", {
      dictType: "NATIONAKLITY"
    }).then(res => {
      if (res.code == 200) {
        this.nationList = res.dataList.map(item => {
          return {
            value: item.dictKey,
            label: item.dictValue
          };
        });
      } else {
        this.$Message.error({
          background: true,
          content: "获取民族信息失败"
        });
      }
    });
    //获取关系
    this.$get("/datamsg/api/common/sys/findDictByDictType", {
      dictType: "FAMILY_RELATIONSHIP"
    }).then(res => {
      if (res.code == 200) {
        this.relationshipList = res.dataList.map(item => {
          return {
            value: item.dictKey,
            label: item.dictValue
          };
        });
      } else {
        this.$Message.error({
          background: true,
          content: "获取民族信息失败"
        });
      }
    });
    // 获取档案
    this.$post("/archives/api/pc/archiveUser/updateArchiveDetail", {
      archiveId: this.info
    }).then(res => {
      if (res.code == 200 && res.data) {
        for (let k in res.data) {
          if (
            !res.data[k] &&
            k != "existingServiceContent" &&
            k != "helpServiceContent" &&
            k != "socialServiceContent"
          ) {
            res.data[k] = {};
          }
        }
        if (res.data.archiveDetailVo && res.data.archiveDetailVo.orgCode) {
          res.data.archiveDetailVo.orgName = "";
        }
        // 建档时间
        this.archivesData = res.data;
        if (
          this.archivesData.archiveDetailVo &&
          this.archivesData.archiveDetailVo.archiveCreate
        ) {
          this.archivesData.archiveDetailVo.archiveCreate = new Date(
            this.archivesData.archiveDetailVo.archiveCreate
          );
        }
      } else {
        this.$Message.error({
          background: true,
          content: res.desc
        });
      }
    });
  },
  components: {
    NeedBaseInfo,
    NeedWork,
    SeeArchives,
    SeeDevService,
    SeeAssessment,
    Referral,
    SeeReturnVisit
  }
};
</script>

<style scoped lang="less">
/deep/.ivu-tabs-content {
  height: calc(100vh - 171px);
}
/deep/.ivu-menu-item-group-title {
  padding-left: 0 !important;
}
.menu {
  position: fixed;
  left: 10px;
  width: 210px !important;
  height: calc(100vh - 162px);
  z-index: 1;
}
.rightContent {
  margin-left: 230px;
  padding: 10px;
}
/deep/.ivu-table-body {
  height: calc(100vh - 260px) !important;
}
/deep/.assessment,
/deep/.service,
/deep/.returnVisit {
  .ivu-table-wrapper {
    height: calc(100vh - 220px) !important;
    overflow: auto;
    .ivu-table {
      overflow: auto !important;
    }
  }
}
.referral {
  &.ivu-tabs-tabpane {
    overflow: auto;
  }
}
</style>
