<template>
    <div class="info">
        <h2 style="text-align:center;margin-bottom: 10px">服务对象基本资料</h2>
       <Form :model="demandData" :inline="true" :label-colon="true" :disabled="true">
      <FormItem label="所属社区" style="width: 100%">
       <Select v-model="demandData.archiveDetailVo.orgCode"  style="width:200px">
        <Option v-for="(item,index) in orgCodeList" :data-label="item.label" :value="item.value" :key="index">{{ item.label }}</Option>
       </Select>
        <Checkbox :value="!demandData.archiveDetailVo.orgCode" style="margin: 0 10px 0 20px">所属其他</Checkbox>
        <Input style="width:200px" :maxlength="20" v-model.trim="demandData.archiveDetailVo.orgName" placeholder="请输入所属其他社区"></Input>
      </FormItem>
      <Divider style="margin:10px 0"/>
      <div class="content">
        <FormItem style="width: 32%">
          <span slot="label" class="validator">姓名:</span>
          <Input :maxlength="20" v-model.trim="demandData.userNeedyVo.name"></Input>
        </FormItem>
        <FormItem label="性别" style="width: 32%">
           <RadioGroup v-model="demandData.userNeedyVo.sex">
            <Radio label="1">男</Radio>
            <Radio label="2">女</Radio>
           </RadioGroup>
        </FormItem>
        <FormItem style="width: 32%">
          <span slot="label" class="validator">联系电话:</span>
          <Input v-model="demandData.archiveDetailVo.mobile"></Input>
        </FormItem>
        <FormItem label="民族" style="width: 32%">
          <Select
        v-model="demandData.userNeedyVo.national"
        style="width: 200px; margin-right: 20px"
      >
        <Option
          v-for="(item,index) in nationList"
          :value="item.value"
          :key="index"
          >{{ item.label }}</Option
        >
      </Select>
        </FormItem>
         <FormItem label="出生日期" style="width: 32%">
          <DatePicker type="date" v-model="demandData.userNeedyVo.birthday" placeholder="请选择出生日期" style="width: 200px"></DatePicker>
        </FormItem>
         <FormItem label="联系地址" style="width: 100%">
          <Input :maxlength="20" v-model.trim="demandData.userNeedyVo.idNumAddr" style="width:calc(100% - 170px)"></Input>
        </FormItem>
         <FormItem label="紧急联系人" style="width: 32%">
          <Input :maxlength="20" v-model.trim="demandData.userContactsVo.name"></Input>
        </FormItem>
         <FormItem label="与案主关系" style="width: 32%">
          <Select
            v-model="demandData.userContactsVo.relationship"
            style="width: 200px; margin-right: 20px"
          >
            <Option :value="item.value" v-for="(item,index) in relationshipList" :key="index">{{item.label}}</Option>
          </Select>
         </FormItem>
          <FormItem label="紧急联系电话" style="width: 32%">
          <Input :maxlength="50" v-model.trim="demandData.userContactsVo.mobile" placeholder="多个号码请用逗号隔开"></Input>
        </FormItem>
        <FormItem label="教育程度" style="width: 100%">
           <RadioGroup v-model="demandData.userDetailVo.eduDegree">
            <Radio label="1">小学</Radio>
            <Radio label="2">初中</Radio>
            <Radio label="3">高中</Radio>
            <Radio label="4">专科</Radio>
            <Radio label="5">本科</Radio>
            <Radio label="6">硕士及以上</Radio>
           </RadioGroup>
        </FormItem>
        <FormItem label="婚姻状况" style="width: 100%">
           <RadioGroup v-model="demandData.userDetailVo.marital">
             <Radio label="0">未知</Radio>
            <Radio label="1">未婚</Radio>
            <Radio label="2">已婚</Radio>
            <Radio label="3">离异</Radio>
            <Radio label="4">丧偶</Radio>
            <Radio label="5">离异再婚</Radio>
            <Radio label="6">丧偶再婚</Radio>
            <Radio label="7">同居</Radio>
           </RadioGroup>
        </FormItem>
        <FormItem label="居所类别" style="width: 100%">
           <RadioGroup v-model="demandData.userHouseVo.houseType">
            <Radio label="1">自置物业</Radio>
            <Radio label="2">租房</Radio>
            <Radio label="3">宿舍</Radio>
            <Radio label="4">其他</Radio>
           </RadioGroup>
           <Input :maxlength="20" v-model.trim="demandData.userHouseVo.houseTypeDesc"</Input>
        </FormItem>
        <FormItem label="居住状况" style="width: 100%">
           <RadioGroup v-model="demandData.userHouseVo.liveType">
            <Radio label="1">独居</Radio>
            <Radio label="2">与亲人同居</Radio>
            <Input :maxlength="20" v-if="demandData.userHouseVo.liveType == 2" v-model.trim="demandData.userHouseVo.liveTypeDesc"></Input>
            <Radio label="3">与朋友同住</Radio>
             <Input :maxlength="20" v-if="demandData.userHouseVo.liveType == 3" v-model.trim="demandData.userHouseVo.liveTypeDesc"></Input>
           </RadioGroup>
        </FormItem>
        <FormItem label="健康状况" style="width: 100%">
           <RadioGroup v-model="demandData.userHealthVo.healthStatus">
            <Radio label="1">健康良好</Radio>
            <Radio label="2">有疾病</Radio>
           </RadioGroup>
        </FormItem>
         <FormItem label="自理能力" style="width: 100%">
           <RadioGroup v-model="demandData.userHealthVo.selfCare">
            <Radio label="1">长期患病能自理</Radio>
            <Radio label="2">部分依赖他人</Radio>
            <Radio label="3">完全依赖他人</Radio>
            <Radio label="4">其他</Radio>
             <Input :maxlength="20" v-model.trim="demandData.userHealthVo.selfCareDesc"></Input>
           </RadioGroup>
        </FormItem>
        <FormItem label="请注明详细健康情况" style="width: 100%">
          <Input :maxlength="30" v-model.trim="demandData.userHealthVo.healthDetail" style="width:calc(100% - 250px)"></Input>
        </FormItem>
        <FormItem label="兴趣爱好" style="width: 100%">
          <Input :maxlength="30" v-model.trim="demandData.userDetailVo.hobby" style="width:calc(100% - 180px)"></Input>
        </FormItem>
         <FormItem label="收入来源" style="width: 100%">
           <RadioGroup v-model="demandData.userIncomeVo.incomeType">
            <Radio label="1">工资</Radio>
            <Radio label="2">退休工资</Radio>
            <Radio label="3">子女/父母供养</Radio>
            <Radio label="4">低保</Radio>
            <Radio label="5">其他</Radio>
             <Input :maxlength="20" v-model.trim="demandData.userIncomeVo.incomeTypeDesc"></Input>
           </RadioGroup>
        </FormItem>
         <FormItem label="个人月收入" style="width: 32%">
          <Input :maxlength="20" v-model.trim="demandData.userIncomeVo.incomeMonthly"></Input>
        </FormItem>
         <FormItem label="工作/学校" style="width: 32%">
          <Input :maxlength="20" v-model.trim="demandData.userDetailVo.workUnit"></Input>
        </FormItem>
         <FormItem label="使用社会服务记录" style="width: 100%">
           <RadioGroup v-model="demandData.useServices">
            <Radio label="0">无</Radio>
            <Radio label="1">曾有（请注明服务及时间）</Radio>
           </RadioGroup>
            <Input v-if="demandData.useServices" v-model.trim="demandData.socialServiceContent" :rows="3"  placeholder="请注明服务及时间" style="width: 80%" type="textarea" show-word-limit :maxlength="400"></Input>
        </FormItem>
      </div>
    </Form>

    <Divider />

     <h2 style="text-align:center;margin-bottom: 10px">分级分类情况</h2>
    <Form :model="demandData" :inline="true" :label-colon="true" :disabled="true">
    <FormItem label="分类" style="width:100%">
      <RadioGroup v-model="demandData.archiveDetailVo.difficultType">
        <Radio label="1">低保低收入对象</Radio>
        <Radio label="2">特困人员</Radio>
        <Radio label="3">留守老年人</Radio>
        <Radio label="4">独居孤寡老人</Radio>
        <Radio label="5">困境儿童</Radio>
        <Radio label="6">贫困重度残疾人</Radio>
        <Radio label="7">其他</Radio>
         <Input :maxlength="20" v-model.trim="demandData.archiveDetailVo.difficultTypeDesc"></Input>
      </RadioGroup>
    </FormItem>
    <FormItem label="分级" style="width:100%;margin-top: 16px">
      <RadioGroup v-model="demandData.archiveDetailVo.difficultLevel">
        <Radio label="1">一级</Radio>
        <Radio label="2">二级</Radio>
        <Radio label="3">三级</Radio>
        <Radio label="4">四级</Radio>
      </RadioGroup>
    </FormItem>
  </Form>

  <Divider />
     <div v-for="(item,index) in needData" :key="index">
    <h2 style="text-align: center">案主问题及需求——{{index}}</h2>
    <Form
      :model="item"
      :inline="true"
      :label-colon="true"
      :disabled="true"
      class="needForm"
    >
      <FormItem :label="subIndex" style="width: 100%" v-for="(subItem,subIndex) in item" :key="subIndex">
        <FormItem
          :label="k.questionDesc"
         style="width: 100%; padding: 0 26px;"
          v-for="(k,i) in subItem"
          :key="i"
        >
          <Input
            :maxlength="100"
            v-model.trim="k.questionResult"
            style="width: calc(100% - 300px)"
          ></Input>
        </FormItem>
      </FormItem>
    </Form>

    <Divider />
    </div>
          <h2 style="text-align:center;margin-bottom: 20px">案主问题及需求——现有</h2>
         <Form :model="needData" :inline="true" :label-colon="true" :disabled='true'>
        <FormItem label="服务及开始时间" style="width: 100%">
          <Input :maxlength="400" v-model.trim="demandData.existingServiceContent" :rows="10" type="textarea" style="width: calc(100% - 200px)"></Input>
        </FormItem>
      </Form>
    </div>
</template>

<script>
   export default {
     props: ['needData',"demandData","orgCodeList","relationshipList","nationList"],
       data() {
            return {
             
           }
        }
      
    }
</script>
    
<style scoped lang='less'>
.info {
     height: calc(100vh - 155px);
     overflow: auto;
     padding-bottom: 20px;
}
    .ivu-form-item {
        margin-bottom: 16px!important;
        .ivu-input-wrapper {
            width: 200px;
      /deep/input {
        border: 0;
        border-bottom: 1px solid #65656c;
        border-radius: 0;
      }
      /deep/input:hover,/deep/input:focus {
           border: 0;
           border-bottom: 1px solid #65656c;
           box-shadow: none;
        }
    }
    /deep/.ivu-input[disabled], fieldset[disabled] .ivu-input,/deep/.ivu-select-disabled .ivu-select-selection,/deep/.ivu-radio-disabled .ivu-radio-inner {
        background-color: #fff!important;
        color: #515a6e;
    }
     /deep/.ivu-radio-disabled .ivu-radio-inner,/deep/.ivu-checkbox-disabled.ivu-checkbox-checked .ivu-checkbox-inner {
      border: 1px solid #515a6e
    }
    /deep/.ivu-checkbox-disabled.ivu-checkbox-checked .ivu-checkbox-inner:after,/deep/.ivu-radio-disabled .ivu-radio-inner:after {
      border-color: #515a6e;
    }
    /deep/.ivu-radio-disabled .ivu-radio-inner:after {
      background-color: #888;
    }
    /deep/.ivu-checkbox-disabled+span {
      color: #515a6e;
    }
  }
</style>