<template>
  <LiefengTable
     :talbeColumns="talbeColumns"
      :tableData="tableData"
        :total="total"
        :curPage="page"
        @hadlePageSize="hadlePageSize"
        :pagesizeOpts="[10, 20, 30, 50]"
        :loading="loading"
        :fixTable="true"
        :pageSize="pageSize"
  ></LiefengTable>
</template>

<script>
import LiefengTable from "@/components/LiefengTable";
export default {
  props: ['info'],
  data() {
    return {
      talbeColumns: [
        {
          title: "具体服务描述",
          key: "content",
          minWidth: 150,
          align: "center",
        },
        {
          title: "服务时间",
          key: "serviceTime",
          minWidth: 150,
          align: "center",
        },
        {
          title: "服务项",
          key: "projects",
          minWidth: 200,
          align: "center",
        },
        {
          title: "跟进社工",
          key: "followName",
          width: 150,
          align: "center",
        },
        {
          title: "创建时间",
          key: "gmtCreate",
          minWidth: 180,
          align: "center",
        },
      ],
      tableData: [],
      page: 1,
      loading: false,
      pageSize: 20,
      total: 0
    };
  },
  methods: {
    hadlePageSize(obj) {
      this.loading = true;
      this.$post('/archives/api/pc/serviceRecord/queryRecordByPage',{
        archiveId: this.info,
        page: obj.page,
        pageSize: obj.pageSize
      }).then(res => {
        if(res.code == 200 && res.data.serviceRecordPages.code == 200 && res.data.serviceRecordPages.dataList) {
          res.data.serviceRecordPages.dataList.map(item => {
            if(item.gmtCreate) {
            item.gmtCreate = this.$core.formatDate(new Date(item.gmtCreate),"yyyy-MM-dd")
            }
            if(item.serviceTime) {
              item.serviceTime = this.$core.formatDate(new Date(item.serviceTime),"yyyy-MM-dd")
            }
          })
          this.tableData = res.data.serviceRecordPages.dataList;
          this.page = res.data.serviceRecordPages.currentPage;
          this.total = res.data.serviceRecordPages.maxCount;
          this.pageSize = res.data.serviceRecordPages.pageSize;
           this.loading = false;
        }else {
          this.$Message.error({
            background: true,
            content: res.desc
          })
        }
      })
    }
  },
  created() {
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize
      })
      
  },
  components: {
    LiefengTable,
  },
};
</script>
    
<style scoped lang='less'>
</style>